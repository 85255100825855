<template>    
    <div>
        <!--{{RankingList}}-->
        <div v-bar class="vs-scrollable" style="height: 393px;">
            <div>
                <vs-table :data="RankingList" class="text-nowrap">
                    <template slot="thead">
                        <vs-th>Rank</vs-th>
                        <vs-th>Estadio</vs-th>
                        <vs-th>Country</vs-th>
                        <vs-th v-if="cap!=0">Nota</vs-th>
                        <vs-th>Geral</vs-th>
                        <vs-th>Arq</vs-th>
                        <vs-th>Gram.</vs-th>
                        <vs-th>Topo</vs-th>
                        <vs-th>Ilum.</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr class="border-top" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td><strong>{{ indextr + 1 }}</strong></vs-td>
                            <vs-td>
                                <div class="d-flex no-block align-items-center">
                                    <div>
                                        <h5 class="mb-0 font-16 font-small"><router-link :to='getRoute(data[indextr].estadio_id)'> {{ data[indextr].estadio_code }}</router-link></h5>
                                        <div v-if="putcode(indextr)" style="white-space: pre">
                                            <small>({{ data[indextr].estadio_name }})</small>
                                        </div>
                                    </div>
                                </div>
                            </vs-td>
                            <vs-td class="flex">
                                <div class="mr-2">
                                    <img :src="getflag2(data[indextr].country_id)"
                                         alt="Pais"
                                         width="30" />
                                    <span class="font-small">
                                        {{ data[indextr].country_name_original }}
                                    </span>
                                </div>
                            </vs-td>
                            <vs-td v-if="cap!=0"><span class="font-small">{{ data[indextr].nota_cap }}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].nota_geral_estadio }}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].nota_geral_arq }}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].last_gramado_nota_geral_geral }}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].last_topo_resumo_nivel_ponderado_sum}}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].nota_ilum}}</span></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <vs-divider position="left-center" v-if="pais === 0">
            Destaques por pais:
        </vs-divider>
        <div v-bar class="vs-scrollable" style="height: 393px;" v-if="pais === 0">
            <div>
                <vs-table :data="DestaqueList" class="text-nowrap">
                    <template slot="thead">
                        <vs-th>Rank</vs-th>
                        <vs-th>Pais</vs-th>
                        <vs-th>Estadio</vs-th>
                        <vs-th>Geral</vs-th>
                        <vs-th>Arq</vs-th>
                        <vs-th>Gram.</vs-th>
                        <vs-th>Topo</vs-th>
                        <vs-th>Ilum.</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr class="border-top" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td><strong>{{ getDestaqueRnk(data[indextr].country_id) }}</strong></vs-td>
                            <vs-td>
                                <div class="mr-2">
                                    <img :src="getflag2(data[indextr].country_id)"
                                         alt="Pais"
                                         width="30" />
                                    <span class="font-small">
                                        {{ data[indextr].country_name_original }}
                                    </span>
                                </div>
                            </vs-td>
                            <vs-td>
                                <div class="d-flex no-block align-items-center">
                                    <div class="d-flex no-block align-items-center">
                                        <div>
                                            <h5 class="mb-0 font-16 font-small">{{ data[indextr].estadio_code }}</h5>
                                            <div v-if="putcode(indextr)" style="white-space: pre">
                                                <small>({{ data[indextr].estadio_name }})</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].nota_geral_estadio }}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].nota_geral_arq }}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].last_gramado_nota_geral_geral }}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].last_topo_resumo_nivel_ponderado_sum}}</span></vs-td>
                            <vs-td><span class="font-small">{{ data[indextr].nota_ilum}}</span></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
    </div>         
</template>
<script>

    import {Ranking} from "../../../services/Arena.js";
    const rk = new Ranking;

   
    export default {
            name: "RankingTable",
      data: () => ({
            title: "RankingTable",

        // Data for data[indextr]
        RankingList: [
          ],
          DestaqueList: [],
          DestaqueCountryRank: [],
        }),
       props: {            
           pais: { type: Number },
           tipork: { type: Number },
           cap: { type: Number }
        },
        created() {
            this.$vs.loading();
            rk.Get(this.pais, this.tipork, 0, this.cap).then((resp) => {
                this.RankingList = resp;     
                this.setdestaques();
                this.$vs.loading.close();
            }, (err) => alert(err));
                
        },
        methods:
        {
            getRoute: function (id) {
                var s = "";
                s = `/estadios/det/${id}`;
                return s;
            },
            putcode(index) {
                if (this.RankingList[index].estadio_code == this.RankingList[index].estadio_name)                
                    return false;
                else
                    return true;
            },
            putcode1(index) {
                if (index == 1)
                    return true;
                else
                    return false;                
            },
            getDestaqueRnk(cid) {
                const found = this.DestaqueCountryRank.find(el => el.cid === cid);                
                return found.rank;                
            },
            getflag2(id) {
                var s = "";
                s = `/img/countries/${id}.png`;
                return s;
            },

            setdestaques() {
                var cid = 0;
                
                for (var i = 0; i < this.RankingList.length; i++)
                {
                    if (cid == 0)
                    {
                        this.DestaqueList.push(this.RankingList[i]);
                        cid = this.RankingList[i].country_id;
                        var paylod = { cid: cid, rank: i+1 };                       
                        this.DestaqueCountryRank.push(paylod);
                    }
                    else
                    {
                        if (cid != this.RankingList[i].country_id)
                        {
                            const found = this.DestaqueCountryRank.some(el => el.cid === this.RankingList[i].country_id);
                            if (!found)
                            {
                                this.DestaqueList.push(this.RankingList[i]);
                                cid = this.RankingList[i].country_id;
                                var paylod2 = { cid: cid, rank: i + 1 };
                                this.DestaqueCountryRank.push(paylod2);
                            }                            
                        }                        
                    }                    
                }
            }
        
        }

};
</script>
