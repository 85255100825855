<template>
  <vs-row vs-justify="center">       
    <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
      <vs-card>
        <!-- Header -->
          <div class="d-md-flex ">
              <!--<div>
      <h3 class="card-title">Projects of the Month</h3>
      <h5 class="card-subtitle text-muted font-weight-normal">Overview of Latest Month</h5>
    </div>-->
              <div class="flex">
                  <vs-select class="w-150px" v-model="filterRkType">
                      <vs-select-item :key="index"
                                      :value="item.value"
                                      :text="item.text"
                                      v-for="(item,index) in filterRkOptions" />
                  </vs-select>
              </div>
              <div class="mr-auto flex">
                  <vs-select class="ss" v-model="filterRkChapter"  width="350px">
                      <vs-select-item :key="index"
                                      :value="item.value"
                                      :text="item.text"
                                      v-for="(item,index) in filterRkChapterOptions" />
                  </vs-select>
              </div>
                  <div class="ml-auto">
                      <vs-select class="w-150px" v-model="filter1">
                          <vs-select-item :key="index"
                                          :value="item.value"
                                          :text="item.text"
                                          v-for="(item,index) in filteroptions1" />
                      </vs-select>
                  </div>
              </div>
              <RankingTable :pais="filter1" :tipork="filterRkType" :cap=filterRkChapter :key="reload"></RankingTable>
</vs-card>
    </vs-col>  
  </vs-row>
</template>
<script>
// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////    
    
import RankingTable from "../survey-table/RankingTable.vue"

 import {Surveys} from "../../../services/Arena.js";
 const sv = new Surveys;

    import { Stadiums } from "../../../services/Arena.js";
    const st = new Stadiums;

// //////////////////////////////////////////
// Export Here
// //////////////////////////////////////////
export default {
  name: "Surveys",
  data: () => ({
    title: "Surveys",
    // Month Table
      reload: false,
      filter1: 0,
    filteroptions1: [            
      ],
      filterRkType: 0,
      filterRkChapter: 0,
      filterRkChapterOptions:[],
      filterRkOptions: [
          { text: 'Ranking geral', value: 0 },
          { text: 'Arq / Eng', value: 1 },
          { text: 'Gramado', value: 2 },
          { text: 'Topografia', value: 3 }, 
          { text: 'Iluminação', value: 4 }
                  
      ],
  }),
  components: {
      RankingTable,    
        },
        created() {

            this.setSideBar(false);
            this.filteroptions1 = [];
            this.filteroptions1.push({ text: "Todos os paises", value: 0 });     
            this.filterRkChapterOptions.push({ text: "Todos os capítulos", value: 0 });
            sv.GetRankigOptions().then((resp) => {                
                for (var i = 0; i < resp.countries.length; i++)
                    {
                    var payload = { 'text': resp.countries[i].countryNameOriginal, 'value': resp.countries[i].countryId };
                    this.filteroptions1.push(payload);                
                }                 

                for (var j = 0; j < resp.surveyStruct.length; j++) {
                    var text;
                    if (resp.surveyStruct[j].survey_structure_is_mother == 1)
                        text = resp.surveyStruct[j].survey_structure_code+ " " +  resp.surveyStruct[j].survey_structure_text_es;
                    else
                        text = "--" + resp.surveyStruct[j].survey_structure_text_es;
                      
                    //text = resp.surveyStruct[j].survey_structure_code + " " + resp.surveyStruct[j].survey_structure_text_es;
                   
                    var payload2 = { 'text': text, 'value': resp.surveyStruct[j].survey_structure_id };

                    this.filterRkChapterOptions.push(payload2);
                }
                //filterRkChapterOptions
                
                st.Get(0).then((resp) => {
                    this.StadiumsList = resp;
                    this.$store.commit("SET_stadiumList", resp);
                    this.$vs.loading.close();
                }, (err) => alert(err));
                
            }, (err) => alert(err));
        },
        watch: {
            filter1:function() {
                this.reload = !this.reload
            },
            filterRkType: function () {
                this.reload = !this.reload
            },
            filterRkChapter: function () {
                this.reload = !this.reload
            }
        },
        methods: {
             setSideBar(val) {
        this.$store.commit("TOGGLE_REDUCE_SIDEBAR", val);
      }
        }
};
</script>